import { useMutation } from '@tanstack/react-query'
import { useNavigate } from '@tanstack/react-router'
import { miraieFields } from '@terass/common/src'
import { V1Import } from '@terass/common/src/firestore/V1Import'

import { useUserEmail } from '@/hooks/useUser'
import { createImport } from '@/utils/import'
import { toast } from '@/utils/standaloneToast'

export type UseDuplicateV1ImportProps = {
  v1import: V1Import
}

export const useDuplicateV1Import = ({
  v1import: { __readers, __writers, __tera_three, formData, shopId },
}: UseDuplicateV1ImportProps) => {
  const navigate = useNavigate()
  const email = useUserEmail()
  const { mutate, isPending, reset } = useMutation({
    mutationFn: () =>
      createImport({
        __readers,
        __writers,
        __tera_three: {
          ...__tera_three,
          baitaiKeisaiIds: {},
        },
        formData: {
          ...formData,
          homes_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
          suumo_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
          athome_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
          rakumachi_c_open_kbn: miraieFields.c_open_kbn.Enum.非掲載,
          images: [],
          shisetsu: [],
        },
        teraSeven: {},
        shopId,
        createdBy: email,
      }),
    onSuccess: ({ id }) => {
      navigate({
        to: `/bukkens/$importId/edit`,
        params: { importId: id },
      })
      toast({
        description: '複製が完了しました。',
        position: 'top',
      })
    },
  })

  return {
    duplicateV1Import: () => mutate(),
    isPending,
    reset,
  }
}
