import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Alert,
  AlertIcon,
  Button,
  ButtonProps,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Tooltip,
  useDisclosure,
} from '@chakra-ui/react'
import { useMutation } from '@tanstack/react-query'
import { miraieFields } from '@terass/common/src'
import { serverTimestamp } from 'firebase/firestore'

import { useValidateMutation } from './useValidateMutation'

import { usePanelImagesLayoutContext } from '@/components/form/ImagesLayout/PanelImagesLayoutContext'
import { MasqueradeInfo } from '@/components/form/MiraieFormApplyButton/MasqueradeInfo'
import { MutationStatusIcon } from '@/components/form/MiraieFormApplyButton/MutationStatusIcon'
import { useMedContract } from '@/hooks/useMedContract'
import { useMiraieFormContext } from '@/hooks/useMiraieForm'
import { useUserEmail, useUserRole } from '@/hooks/useUser'
import { useV1ImportDocument } from '@/hooks/useV1ImportDocument'
import { useV1ImportParam } from '@/hooks/useV1ImportParam'
import {
  publishMediaCoverage,
  updateTeraThreeMediaCampaign,
} from '@/utils/functions'
import { updateImport } from '@/utils/import'
import { toast } from '@/utils/standaloneToast'

export const MiraieFormApplyButton = (props: ButtonProps) => {
  const { data: teraThree } = useMedContract()
  const importId = useV1ImportParam()
  const { getValues } = useMiraieFormContext()
  const {
    isOpen: isConfirmOpen,
    onOpen: onOpenConfirm,
    onClose: onCloseConfirm,
  } = useDisclosure()
  const { savedPanelImagesLayout } = usePanelImagesLayoutContext()
  const validateMutation = useValidateMutation(() =>
    updateImportMutation.mutate(),
  )
  const email = useUserEmail()
  const updateImportMutation = useMutation({
    mutationFn: async () => {
      const { teraSeven, ...formData } = getValues()
      const isAllClosed = miraieFields.c_open_kbn.isAllClosed(formData)

      await updateImport(importId, {
        formData: {
          ...formData,
          ...miraieFields.mngr_open_kbn.getValues(formData),
          torihiki_kbn: teraThree?.mediationCategory ?? undefined,
          ...(formData.portal_staff
            ? {}
            : // 解除する場合は、portal_staff ではなく portal_staff_id を null にする必要がある（portal_staff は null だとエラーになるため undefined）
              { portal_staff: undefined, portal_staff_id: null }),
        },
        teraSeven,
        panelImagesLayout: savedPanelImagesLayout,
        ...(isAllClosed
          ? {
              status: 'closed',
              closedAt: serverTimestamp(),
            }
          : {
              status: 'published',
              publishedAt: serverTimestamp(),
            }),
        updatedBy: email,
      })
    },
    onSuccess: () => publishMediaCoverageMutation.mutate(),
  })
  const publishMediaCoverageMutation = useMutation({
    mutationFn: async () => {
      await publishMediaCoverage({ importId })
    },
    onSuccess: () => syncTeraThreeMutation.mutate(),
  })
  const syncTeraThreeMutation = useMutation({
    mutationFn: async () => {
      await updateTeraThreeMediaCampaign({ importId })
    },
    onSuccess: () => {
      resetMutations()
      toast({
        description: '処理が正常終了しました。',
      })
    },
  })
  const mutations = {
    validateMutation,
    updateImportMutation,
    publishMediaCoverageMutation,
    syncTeraThreeMutation,
  } as const
  const mutationNames = [
    'validateMutation',
    'updateImportMutation',
    'publishMediaCoverageMutation',
    'syncTeraThreeMutation',
  ] as const satisfies (keyof typeof mutations)[]
  const isPending = mutationNames.some(
    (name) => mutations[name].status === 'pending',
  )
  const isProgressOpen = mutationNames.some(
    (name) => mutations[name].status !== 'idle',
  )
  const resetMutations = () =>
    mutationNames.forEach((name) => mutations[name].reset())
  const role = useUserRole()
  const status = useV1ImportDocument(({ status }) => status)
  const baikaiKeiyakuId = useV1ImportDocument(
    ({ __tera_three: { baikaiKeiyakuId } }) => baikaiKeiyakuId,
  )
  const opLabel = status === 'draft' ? '公開' : '更新'
  const isPublishDisabled =
    status === 'draft' && role === 'agent' && baikaiKeiyakuId !== undefined

  return (
    <>
      <Tooltip
        isDisabled={!isPublishDisabled}
        label="公開作業は本部に依頼してください。"
      >
        <Button
          isLoading={isPending}
          onClick={
            role === 'assistant'
              ? onOpenConfirm
              : () => validateMutation.mutate()
          }
          {...props}
          isDisabled={isPublishDisabled}
        >
          {opLabel}
        </Button>
      </Tooltip>
      {role === 'assistant' && (
        <Modal isOpen={isConfirmOpen} onClose={onCloseConfirm}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>確認</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <MasqueradeInfo />
            </ModalBody>
            <ModalFooter gap={4}>
              <Button onClick={onCloseConfirm} colorScheme="gray">
                キャンセル
              </Button>
              <Button
                onClick={() => {
                  onCloseConfirm()
                  validateMutation.mutate()
                }}
              >
                {opLabel}する
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      <Modal
        isOpen={isProgressOpen}
        onClose={resetMutations}
        closeOnEsc={!isPending}
        closeOnOverlayClick={!isPending}
      >
        <ModalOverlay />
        <ModalContent>
          {isPending || <ModalCloseButton />}
          <ModalHeader>
            {isPending ? '処理中...' : 'エラーがあります'}
          </ModalHeader>
          <ModalBody>
            <Accordion
              index={mutationNames.findIndex(
                (name) => mutations[name].status === 'error',
              )}
            >
              {mutationNames.map((name, index) => {
                const step = index + 1
                const mutation = mutations[name]
                return (
                  <AccordionItem key={name}>
                    <AccordionButton
                      display="grid"
                      gridTemplateColumns="auto 1fr auto"
                      gap={2}
                      alignItems="center"
                      opacity={mutation.status === 'idle' ? 0.5 : 1}
                    >
                      <MutationStatusIcon status={mutation.status} />
                      <Text align="left">
                        {
                          {
                            validateMutation: '入力内容の検証',
                            updateImportMutation: '入力内容の保存',
                            publishMediaCoverageMutation: '媒体掲載の公開',
                            syncTeraThreeMutation: 'Salesforce への同期',
                          }[name]
                        }
                      </Text>
                      <Text>
                        {step}/{mutationNames.length}
                      </Text>
                    </AccordionButton>
                    <AccordionPanel>
                      {mutation.status === 'error' && (
                        <Alert status="error">
                          <AlertIcon />
                          {mutation.error.message}
                        </Alert>
                      )}
                    </AccordionPanel>
                  </AccordionItem>
                )
              })}
            </Accordion>
          </ModalBody>
          <ModalFooter>
            <Button isLoading={isPending} onClick={resetMutations}>
              閉じる
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
